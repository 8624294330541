(function ($) { $.browserTest = function (a, z) { var u = 'unknown', x = 'X', m = function (r, h) { for (var i = 0; i < h.length; i = i + 1) { r = r.replace(h[i][0], h[i][1]); } return r; }, c = function (i, a, b, c) { var r = { name: m((a.exec(i) || [u, u])[1], b) }; r[r.name] = true; r.version = (c.exec(i) || [x, x, x, x])[3]; if (r.name.match(/safari/) && r.version > 400) { r.version = '2.0'; } if (r.name === 'presto') { r.version = ($.browser.version > 9.27) ? 'futhark' : 'linear_b'; } r.versionNumber = parseFloat(r.version, 10) || 0; r.versionX = (r.version !== x) ? (r.version + '').substr(0, 1) : x; r.className = r.name + r.versionX; return r; }; a = (a.match(/Opera|Navigator|Minefield|KHTML|Chrome/) ? m(a, [[/(Firefox|MSIE|KHTML,\slike\sGecko|Konqueror)/, ''], ['Chrome Safari', 'Chrome'], ['KHTML', 'Konqueror'], ['Minefield', 'Firefox'], ['Navigator', 'Netscape']]) : a).toLowerCase(); $.browser = $.extend((!z) ? $.browser : {}, c(a, /(camino|chrome|firefox|netscape|konqueror|lynx|msie|opera|safari)/, [], /(camino|chrome|firefox|netscape|netscape6|opera|version|konqueror|lynx|msie|safari)(\/|\s)([a-z0-9\.\+]*?)(\;|dev|rel|\s|$)/)); $.layout = c(a, /(gecko|konqueror|msie|opera|webkit)/, [['konqueror', 'khtml'], ['msie', 'trident'], ['opera', 'presto']], /(applewebkit|rv|konqueror|msie)(\:|\/|\s)([a-z0-9\.]*?)(\;|\)|\s)/); $.os = { name: (/(win|mac|linux|sunos|solaris|iphone)/.exec(navigator.platform.toLowerCase()) || [u])[0].replace('sunos', 'solaris') }; if (!z) { $('html').addClass([$.os.name, $.browser.name, $.browser.className, $.layout.name, $.layout.className].join(' ')); } }; $.browserTest(navigator.userAgent); })(jQuery);
if (document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1")) { $('html').addClass('svg'); }
var istouch = !!('ontouchstart' in window);

function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }
    return false;
}
function inputLimiter(e, tipo) {
    var AllowableCharacters = '';

    if (tipo == 'letras') { AllowableCharacters = ' ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; }
    if (tipo == 'int') { AllowableCharacters = '1234567890'; }
    if (tipo == 'float') { AllowableCharacters = '1234567890.'; }
    if (tipo == 'codpostal') { AllowableCharacters = '1234567890-'; }

    var k = document.all ? parseInt(e.keyCode) : parseInt(e.which);
    if (k != 13 && k != 8 && k != 0) {
        if ((e.ctrlKey == false) && (e.altKey == false)) {
            return (AllowableCharacters.indexOf(String.fromCharCode(k)) != -1);
        } else {
            return true;
        }
    } else {
        return true;
    }
}

/* -- FUNCOES AUXILIARES -- */
var aux = {
    htmlEncode: function (value) {
        return $('<div/>').text(value).html();
    },
    htmlDecode: function (value) {
        return $('<div/>').html(value).text();
    },
    sucesso: function (texto) {
        alert(texto);
    },
    erro: function (texto) {
        alert(texto);
    },
    getTimeFromSeconds: function (segundos) {
        var hours = parseInt(segundos / 3600) % 24,
            minutes = parseInt(segundos / 60) % 60,
            seconds = segundos % 60;

        var result = "";
        if (hours > 0) {
            result = (hours < 10 ? "0" + hours : hours) + "h" + (minutes < 10 ? "0" + minutes : minutes) + "m" + (seconds < 10 ? "0" + seconds : seconds) + "s";
        } else if (minutes > 0) {
            result = (minutes < 10 ? "0" + minutes : minutes) + "m" + (seconds < 10 ? "0" + seconds : seconds) + "s";
        } else if (seconds > 0) {
            result = (seconds < 10 ? "0" + seconds : seconds) + "s";
        } else {
            result = "-";
        }

        return result;
    },
    cleanString: function (string) {

        return string.split("'").join("\\u0027").split('"').join("\\u0022");;
    },

    countDown: function (start, update, callback) {
        start--;
        update(start);
        if (start > 0) {
            setTimeout(function () {
                aux.countDown(start, update, callback)
            }, 1000);
        } else {
            callback();
        }
    },
    scrollElement: function (elem, tempo) {
        $('html, body').animate({ scrollTop: $(elem).offset().top }, tempo);
    },
	checkSize: function (valor) {
        if (valor < 10) {
            valor = "0" + valor;
        }
        return valor;
	},
	ColorLuminance: function (hex, lum) {

	    hex = String(hex).replace(/[^0-9a-f]/gi, '');
	    if (hex.length < 6) {
	        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	    }
	    lum = lum || 0;

	    var rgb = "#", c, i;
	    for (i = 0; i < 3; i++) {
	        c = parseInt(hex.substr(i * 2, 2), 16);
	        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
	        rgb += ("00" + c).substr(c.length);
	    }
	    return rgb;
	},
	isEmail: function (email) {
	    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(email.trim());
	},
}
var cookies = {
    add: function (cookie, valor) {

        if (!this.existe(cookie, valor)) {

            var valoresCookie = this.get(cookie);
            if (valoresCookie != "") {
                valoresCookie += ",";
            }
            valoresCookie += valor;

            this.create(cookie, valoresCookie);
        }
    },
    remove: function (cookie, valor) {
        var cookieVal = this.get(cookie),
             count = 0;
        if (cookieVal != null) {

            var valoresCookie = cookieVal.split(','),
               stringFinal = "";

            for (var i = 0; i < valoresCookie.length; i++) {
                if (valor == valoresCookie[i]) {
                    count++;
                } else {
                    if (stringFinal != "") {
                        stringFinal += ",";
                    }
                    stringFinal += valoresCookie[i];
                }
            }

            this.create(cookie, stringFinal);

        }
        return count;

    },
    existe: function (cookie, valor) {
        var cookieVal = this.get(cookie);
        if (cookieVal != null) {
            var valoresCookie = cookieVal.split(','),
                existe = false;

            for (var i = 0; i < valoresCookie.length; i++) {
                if (valor == valoresCookie[i]) {
                    existe = true;;
                    i = valoresCookie.length;;
                }
            }
            return existe;
        } else {
            return false;
        }
    },
    get: function (name) {

        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    create: function (name, value, nrDias) {
        if (nrDias == null) {
            nrDias = 7;
        }
        var date = new Date();
        date.setTime(date.getTime() + (nrDias * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();

        document.cookie = name + "=" + value + expires + "; path=/";
    },
    erase: function (name) {
        this.create(name, '', -1);
    }
}





if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (elt /*, from*/) {
        var len = this.length >>> 0;
        var from = Number(arguments[1]) || 0;
        from = (from < 0) ? Math.ceil(from) : Math.floor(from);
        if (from < 0) from += len;

        for (; from < len; from++) {
            if (from in this && this[from] === elt) return from;
        }
        return -1;
    };
}